function setting_business_hours_page(props) {
  const [awayBusinessTime, setAwayBusinessTime] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState([]);
  const [businessHoursStatus, setBusinessHoursStatus] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const handleChange = (props) => {
    if (props && props.time) {
      const allDayRanges = props.time.flatMap((day) => day.dayRange);
      SaveBusinessHourTime(allDayRanges);
    }
    setShow(!show);
  };

  React.useEffect(() => {
    setIsLoading(true);
    setDate([
      { text: props.lang.__business_hours_monday, day: "monday" },
      { text: props.lang.__business_hours_tuesday, day: "tuesday" },
      { text: props.lang.__business_hours_wednesday, day: "wednesday" },
      { text: props.lang.__business_hours_thursday, day: "thursday" },
      { text: props.lang.__business_hours_friday, day: "friday" },
      { text: props.lang.__business_hours_saturday, day: "saturday" },
      { text: props.lang.__business_hours_sunday, day: "sunday" },
    ]);
    let timeArray = generateTimeArray();
    timeArray.push("24:00");
    setDateRange(timeArray);

    getAwayBusinessTime();
  }, []);

  const generateTimeArray = () => {
    const times = [];

    const start = moment().startOf("day");
    const end = moment().endOf("day");

    while (start <= end) {
      times.push(start.format("HH:mm"));
      start.add(15, "minutes");
    }

    return times;
  };

  const subtractOneMinute = (timeString) => {
    const dateTime = moment(timeString, "HH:mm");
    return dateTime.subtract(1, "minutes").format("HH:mm");
  };

  const getAwayBusinessTime = () => {
    axios({
      method: "get",
      url:
        backend +
        "away-business/" +
        props.business_data.uid +
        "/" +
        props.business_data.pid +
        "/" +
        props.business_data.app_id +
        "/get-away-business-info",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
    }).then((res) => {
      if (res.data.result) {
        setBusinessHoursStatus(res.data.businessInfo.business_hours_status);
        setAwayBusinessTime(res.data.awayBusinessTime);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const updateBusinessHourStatus = (StatusWebhook) => {
    axios({
      method: "put",
      url:
        backend +
        "away-business/" +
        props.business_data.uid +
        "/" +
        props.business_data.pid +
        "/" +
        props.business_data.app_id +
        "/update-business-hour-status",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        status: StatusWebhook,
      }),
    }).then((res) => {
      if (res.data.result) {
        props.toast.success(props.lang.__business_hours_setting_success)
        props.toggleBusiness(StatusWebhook);
        setBusinessHoursStatus(StatusWebhook);
      } else {
        props.toast.error(props.lang.__business_hours_setting_fail)
      }
    });
  };

  const toggleBusinessHourStatus = (status) => {
    props
      .confirmDialog(
        "__business_hours_setting",
        "__business_hours_toggle_description",
        {
          btn_class: "-green",
          submit_text: "__ok",
        }
      )
      .then(() => {
        updateBusinessHourStatus(status);
      });
  };

  const SaveBusinessHourTime = (timeArray) => {
    axios({
      method: "put",
      url:
        backend +
        "away-business/" +
        props.business_data.uid +
        "/" +
        props.business_data.pid +
        "/" +
        props.business_data.app_id +
        "/update-business-hour-time",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.business_data.access,
        Authorization: "Bearer " + props.business_data.token,
      },
      data: JSON.stringify({
        time_range: timeArray,
      }),
    }).then((res) => {
      if (res.data.result) {
        setAwayBusinessTime(timeArray);
      }
    });
  };

  const generateDateRange = (day) => {
    let width = 0;
    let allWidth = 0;
    let createdCount = 0;
    let timePrevious = "";
    const dayOfWeek = _.filter(awayBusinessTime, { day_of_week: day });
    return (
      <div style={{ display: "flex", width: "100%" }}>
        {dateRange.map((item, index) => {
          let dateValueStart = _.find(dayOfWeek, { start_time: item }) || false;
          let dateValueEnd = _.find(dayOfWeek, { end_time: item }) || false;
          if (
            (dateValueStart ||
              dateValueEnd ||
              index === dateRange.length - 1) &&
            index !== 0
          ) {
            createdCount++;
            width += 1.03092;
            if (index === dateRange.length - 1) {
              width = 100 - allWidth;
            }
            allWidth += width;

            if (dateValueEnd) {
              timePrevious = dateValueEnd.end_time;
            }
            const element = (
                <ReactTooltip
                  width={width}
                  text={dateValueEnd.start_time === "00:00" && dateValueEnd.end_time === "24:00" ? props.lang.__all_day : `${
                    dateValueEnd
                      ? moment(dateValueEnd.start_time, "HH:mm").format("HH:mm")
                      : createdCount === 1
                      ? "00:00"
                      : moment(timePrevious, "HH:mm").format("HH:mm")
                  } - ${
                    dateValueEnd
                      ? dateValueEnd.end_time === "24:00"
                        ? "24:00"
                        : moment(dateValueEnd.end_time, "HH:mm").format("HH:mm")
                      : index === dateRange.length - 1
                      ? "24:00"
                      : subtractOneMinute(dateValueStart.start_time)
                  }`}
                >
                  <div
                    style={{
                      backgroundColor: dateValueEnd ? "#6DA7FD" : "#00000028",
                      minHeight: "8px",
                      maxHeight: "8px",
                      marginRight: "4px",
                      marginTop: "8px",
                      borderRadius: "8px",
                    }}
                  ></div>
                </ReactTooltip>
            );
            width = 0;
            return element;
          } else {
            width += 1.03092;
          }
        })}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <i class="icon icon-circle-notch animate-spin"></i>{" "}
            <span>{props.lang.__loading}</span>
          </div>
        </div>
      ) : (
        <div>
          <div
            class="sp-row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
              padding: "0 20px",
              borderBottom: "1px solid rgb(225, 231, 242)",
            }}
          >
            <div>
              <span class="sp-label">{props.lang.__business_hours}</span>
              <ReactTooltip text={props.lang.__manual_business_hours} tooltipWidth={160}>
                <a
                href="https://help.readyplanet.com/rchat/business-hours"
                target="_blank"
              >
                <i
                  class="icon-help-circled sp-link"
                  tooltip-placement="right"
                ></i>
              </a>
              </ReactTooltip>
            </div>
            <Toggle defaultStatus={businessHoursStatus}>
              <label>
                <input
                  type="checkbox"
                  onChange={() => {
                    if(!businessHoursStatus){
                      toggleBusinessHourStatus(!businessHoursStatus)
                    } else {
                      updateBusinessHourStatus(!businessHoursStatus)
                    }
                  }
                  }
                />
                <div class="toggle-button cursor-pointer">
                  <span></span>
                </div>
              </label>
            </Toggle>
          </div>
          <BusinessHoursModal
            show={show}
            handleChange={handleChange}
            lang={props.lang}
            businessHoursTime={awayBusinessTime}
            date={date}
            dateRange={dateRange}
            toast={props.toast}
          />
          {businessHoursStatus ? (
            <div style={{ padding: window.innerWidth < 768 ? "0 4%" : "0 14%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "10px 0",
                }}
              >
                <Button
                  color="primary"
                  onClick={handleChange}
                >
                  {props.lang.__business_hours_edit}
                </Button>
              </div>
              <div
                style={{
                  margin: "20px 0",
                  padding: "20px 20px",
                  border: "1px solid #00000028",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "10%",
                  }}
                >
                  <div style={{ color: "#1C2B33A6" }}>
                    00:00
                  </div>
                  <div style={{ color: "#1C2B33A6" }}>
                    24:00
                  </div>
                </div>

                {date &&
                  date.map((item) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          margin: "10px 0",
                        }}
                      >
                        <div style={{ minWidth: "82px" }}>{item.text}</div>
                        <div style={{ width: "90%" }}>
                          <div style={{ display: "flex", marginBottom: "6px" }}>
                            {_.find(awayBusinessTime, {
                              day_of_week: item.day,
                            }) ? (
                              generateDateRange(item.day)
                            ) : (
                              <ReactTooltip width={'100'} text={props.lang.__all_day}>
                                <div
                                  style={{
                                    backgroundColor: "#00000028",
                                    width: "100%",
                                    minHeight: "8px",
                                    maxHeight: "8px",
                                    marginRight: "4px",
                                    marginTop: "8px",
                                    borderRadius: "8px",
                                  }}
                                ></div>
                              </ReactTooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#6DA7FD",
                      minWidth: 15,
                      width: 15,
                      minHeight: 15,
                      height: 15,
                      borderRadius: "50%",
                      margin: "auto 6px",
                    }}
                  />
                  <div style={{ color: "#1C2B33A6", marginRight: "20px" }}>
                    {props.lang.__business_hours}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#00000028",
                      minWidth: 15,
                      width: 15,
                      minHeight: 15,
                      height: 15,
                      borderRadius: "50%",
                      margin: "auto 6px",
                    }}
                  />
                  <div style={{ color: "#1C2B33A6" }}>
                    {props.lang.__business_hours_outside}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
